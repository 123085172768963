import React, { useState } from 'react'
import Blog_carts from '../Components/Blog_carts';
import Detailblog from '../Components/Detailblog';

const Blogpage = () => {
  const [open, setOpen] = useState(false)
  return (
    <div>
        {!open && <Blog_carts setOpen={setOpen}/>}
      {open && <Detailblog/>}
     </div>
  )
}

export default Blogpage

import React from 'react'

const Detailblog = () => {
  return (
    <>
    <div className='p-5 md:p-10 w-[100%] '>
      <div className='text-[10px] md:text-[20px] flex flex-col gap-4'>

    <p className='text-[20px] md:text-[40px] font-normal'>Launch of Twigyy Beta </p>
    <p className='text-[10px] md:text-[15px]'>August 15, 2024 </p>
        <p className='text-justify'>We are thrilled to announce the beta release of Twigyy, the next big thing in social media where creativity meets connection. Twigyy is designed for those who love to share moments, discover inspiration, and connect with a vibrant community. Today marks a significant milestone in our journey, and we can't wait for you to be a part of it. Join us on this exciting journey and be a part of a community where your creativity is celebrated, your connections are meaningful, and your inspiration is limitless. Welcome to Twigyy, where you can connect, create, and inspire.</p>
        <p className='text-justify'>What is Twigyy?</p>
        <p className='text-justify'>
        Twigyy is more than just a social media platform. It's a space where you can express your creativity through stunning photos and captivating videos. Whether you're a professional photographer, an aspiring influencer, or someone who enjoys capturing everyday moments, Twigyy offers the perfect environment to showcase your unique perspective.</p>
        <p className='text-justify'>Key Features:</p>
        <ul className='list-disc px-[35px] text-[11px] md:text-[16px]'>
          <li>Stunning Visual Sharing: Share high-quality photos and videos effortlessly with your followers.</li>
          <li>Intuitive User Interface: Enjoy a seamless and user-friendly experience that makes connecting with others a joy.</li>
          <li>Explore and Discover: Find inspiration from a global community of creators and discover content that resonates with you.</li>
          <li>Engage and Interact: Connect with friends and followers through likes, comments, and direct messages.</li>
          <li>Personalized Feed: Get personalized content recommendations based on your interests and activities.</li>
        </ul>
        <p className='text-justify'>Join the Beta community</p>
        <p className='text-justify'>As a beta tester, you'll have the opportunity to shape the future of Twigyy. Your feedback is invaluable to us as we work to improve the platform and introduce new features. Here’s how you can get started:</p>
        <ul className='list-disc px-[35px] text-[11px] md:text-[16px]'>
          <li>Sign Up: Visit our website and sign up to join the beta community.</li>
          <li>Start Sharing: Explore the website, start sharing your moments, and connect with others.</li>
        </ul>
        <p className='text-justify'>Why Join the Beta?</p>
        <p className='text-justify'>
By joining the Twigyy beta, you get to be one of the first to experience our new platform. You’ll have access to exclusive features and updates, and your input will directly impact the development of Twigyy. We’re committed to building a community-driven platform, and we need your help to make it the best it can be.
</p>
        <p className='text-justify'>What's Next?</p>
        <p className='text-justify'>We have an exciting roadmap ahead, with plans to introduce more features, improve performance, and expand our community. Stay tuned for updates and new releases. Your journey with Twigyy has just begun, and we’re excited to have you on board.</p>
        <p className='text-justify'>
        Share Your Thoughts</p>
        <p className='text-justify'>We want to hear from you! Share your experiences, suggestions, and feedback with us. Connect with us on our social media channels and use the hashtag #TwigyyBeta to join the conversation.</p>
        <p className='text-justify'>Thank You</p>
        <p className='text-justify'>Thank you for being a part of our beta community. Together, we can create a platform that not only connects people but also inspires creativity and fosters meaningful interactions.</p>
        <p className='text-justify'>Welcome to Twigyy – where you can connect, create, and inspire.</p>
      </div>

    </div>
    
    </>
  )
}

export default Detailblog
import React, { useState, useEffect } from 'react';
import '../../Styles/Slider.css'; // Assume you have a CSS file for slider styles

const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalItems = 3;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
      }, 3000);
      return () => clearInterval(interval);
    }, [totalItems]);
  
    const handleRadioChange = (index) => {
      setCurrentIndex(index);
    };
  
    return (
      <div className={`container ${currentIndex === 1 ? 'blue' : ''}`}>
        <input
          type="radio"
          name="slider"
          id="item-1"
          checked={currentIndex === 0}
          onChange={() => handleRadioChange(0)}
        />
        <input
          type="radio"
          name="slider"
          id="item-2"
          checked={currentIndex === 1}
          onChange={() => handleRadioChange(1)}
        />
        <input
          type="radio"
          name="slider"
          id="item-3"
          checked={currentIndex === 2}
          onChange={() => handleRadioChange(2)}
        />
        
        <div className="cards">
            <label className="card" htmlFor="item-1" id="song-1">
              <img
                src={require("../../Images/photo_1.jpg")}
                alt="song"
              />
            </label>
            <label className="card" htmlFor="item-2" id="song-2">
              <img
                src={require("../../Images/photo_2.jpg")}
                alt="song"
              />
            </label>
            <label className="card" htmlFor="item-3" id="song-3">
              <img
              src={require("../../Images/photo_3.jpg")}
              alt="song"
              />
            </label>
        </div>
      </div>
    );
  };
  
  export default Slider;
import React from 'react'

const Search = () => {
  return (
    <>
    <div className="flex flex-col gap-[20px] w-[100%]">

    <div className="font-medium text-[30px]">Search</div>
    <p>You can discover new things on Twigyy based on people you follow and feeds you like.
    </p>
    <div>
        
    <ul className="list-disc ">
            <p>How to search from computer:</p>

            <li className="mx-[35px]">
            Click on account(🔍) from the the navigation bar to go to Search Page

            </li>
            <li className="mx-[35px]">
            Click on the search bar and write that you want to search 

            </li>
            <li className="mx-[35px]">
            After that you can find #hashtags, profiles and feeds related to your search

            </li>
          </ul>
    </div>

    </div>
    
    </>
  )
}

export default Search
import React from "react";
// import SearchIcon from '@mui/icons-material/Search';

const Helpmiddlebar = () => {
  return (
    <>
      <div className="flex flex-col w-[100%] px-10">
        <div>
          <p className="text-[30px]  font-medium">How can we help you?</p>
          <label class="relative block w-[40%]">
            <span class="sr-only">Search</span>
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"></svg>
            </span>
            <input
              class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              placeholder="Search help for anything..."
              type="text"
              name="search"
            />
          </label>
        </div>
        <div>
          <p className="text-[20px]  font-medium">Featured topics</p>
        </div>
        <div>
          <p>About twigyy</p>
          <p>About your account pages</p>
          <p>Reels</p>
          <p>blogs on help center</p>
        </div>
        <div>
          <p className="text-[30px]  font-medium">
            Looking for something else?
          </p>
        </div>
      </div>
    </>
  );
};

export default Helpmiddlebar;

import React, { useState } from 'react'

import Helpsidebar from '../Components/Helpsidebar';
import Helpmiddlebar from '../Components/Helpmiddlebar';
import Yourprofile from '../Components/Yourprofile';
import Message from '../Components/Message';
import Search from '../Components/Search';
import Takes from '../Components/Takes';
import Twigs from '../Components/Twigs';
import Tales from '../Components/Tales';
import Photo_and_Video from '../Components/Photo_and_Video';

import "../Styles/DropDown.css"

const Helppage = () => {
  const [sections, setSections] = useState({
    yourProfile: false,
    twig: false,
    take: false,
    message: false,
    search: false,
    tales: false,
  });
  
  function Help_On(section) {
    setSections(prevState => {
      const resetState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
  
      // If no section is provided, return the reset state.
      if (!section) {
        return resetState;
      }
      
      // If a section is provided, set that section to true.
      return {
        ...resetState,
        [section]: true,
      };
    });
  }

  return (
    <>
    <div className='flex justify-between mt-10 h-[100vh] overflow-scroll'>

    <Helpsidebar Help_On={Help_On}/>
    { sections.yourProfile || sections.tales || sections.message || sections.search || sections.take || sections.twig ? <></> : <Helpmiddlebar/>}
    {sections.yourProfile && <Yourprofile/>} 
    {sections.tales && <Tales/>}
    {/* <Photo_and_Video/> */}
    {sections.message && <Message/>}
    {sections.search && <Search/>}
    {sections.take && <Takes/>}
    {sections.twig && <Twigs/>}

    </div>
    
    </>
  )
}

export default Helppage
import React, { useEffect, useRef } from "react";
import tales from "../../Images/tales.svg";
import photo_1 from "../../Images/photo_1.jpg";
import photo_2 from "../../Images/photo_2.jpg";
import photo_3 from "../../Images/photo_3.jpg";
import Tale_video from "../Tale_video";

const Tales_pg = (props) => {
  return (
    <>
      <div ref={props.taleRef} className="fade-in w-full h-[100%] flex flex-col-reverse md:flex-row items-center justify-around pl-2 md:pl-20">
        
        <div className="h-[60%] md:h-fit md:w-[50%] flex flex-col items-center justify-center md:justify-normal scale-[.6] md:scale-100 ">
          <section className="w-full md:h-full mr-[120px] md:mr-0">
            <Tale_video />
          </section>
          <p className="tales_text_2 mt-2">Share your stories in tales</p>
        </div>

        <section id="tale_ms" className="tales_text_1">
          <div className="flex">
            <p id="tale_pop" className="op">M</p>
            <span className="overflow-hidden">
              <p id="tale_slide" className="op">oments</p>
            </span>
          </div>
          <div className="flex">
            <p id="tale_pop_1" className="op">M</p>
            <span className="overflow-hidden">
              <p id="tale_slide_1" className="op">ade</p>
            </span>
          </div>
          <div className="flex">
            <p id="tale_pop_2" className="op">M</p>
            <span className="overflow-hidden">
              <p id="tale_slide_2" className="op">emories</p>
            </span>
          </div>
        </section>
      </div>
    </>
  );
};

export default Tales_pg;

import React from 'react'
import { Link } from 'react-router-dom'

const Blog_carts = (props) => {
  return (
    <>
    <div className='my-5 md:my-16'>

    <div onClick={() => props.setOpen(true)} className='flex flex-col md:flex-row justify-center border-dashed border-4 border-[#757474] p-5 md:p-14 rounded-[54px] gap-10 w-[80%] mx-auto mt-16'>
        <img className='rounded-[40px] h-full w-[200px]' src='https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/images%2FTwigyy%20logo.png?alt=media&token=9d1e93b8-35ee-4b85-9da3-914cc60c95be'/>
        <div className='flex flex-col gap-4'>
        <p className='text-[20px] md:text-[30px] font-normal'>Launch of Twigyy Beta </p>
        <p className='text-[15px]'>August 15, 2024 </p>
        <p className='text-[15px] md:text-[20px] text-justify'>We are thrilled to announce the beta release of Twigyy, the next big thing in social media where creativity meets connection. Twigyy is designed for those who love to share moments, discover inspiration, and connect with a vibrant community. Today marks a significant milestone in our journey, and we can't wait for you to be a part of it. Join us on this exciting journey and be a part of a community where your creativity is celebrated, your connections are meaningful, and your inspiration is limitless. Welcome to Twigyy, where you can connect, create, and inspire...</p>

        </div>
    </div>
    
    </div>
    </>
        
  )
}

export default Blog_carts

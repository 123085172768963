import React from 'react'
import img_1 from '../Images/Pg_1_img.svg'
import '../Styles/Page_1.css'

const Page_1 = () => {
  return (
<>

<div className='h-[99.98%] flex flex-col gap-20 md:flex-row md:gap-0 justify-between px-10 z-1'>

    <div className='flex flex-col justify-center'>
        <p className='text_1 text-[50px] md:text-[100px] font-semibold text-[#01538B]'>Connect</p>
        <p className='text_2 text-[50px] md:text-[100px] font-semibold text-[#18b08740] ml-[180px] md:ml-[200px]'>Create</p>
        <p className='text_3 text-[50px] md:text-[100px] font-semibold text-[#cbc6982f]'>Inspire</p>
    </div>

    <img className='' src={img_1}/>

</div>

</>
  )
}

export default Page_1
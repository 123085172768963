import React from "react";
import emailjs from "emailjs-com";

const SendFeedbackReply = (formData) => {
  const { name, email } = formData;

  const emailContent = `
    <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Email</title>

  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Kaushan+Script&display=swap" rel="stylesheet">

  <style>
      body{
        margin: 0;
        padding: 0;
        background-color: #FFF0D2;
      }
      .whole_bg{
        /* overflow: scroll; */
        width: 100%;
        height: 100%;
      }
      .content_container{
        width: clamp( 320px, 60%, 70%);
        /* height: 85%; */
        background-color: white;
        border-radius: 20px;
        padding: 10px 0px;
        margin: 0 auto;
      }
      .yellow_sec {
        width: clamp( 156px, 30vw, 700px);
        /* width: 70%; */
        /* width: fit-content; */
        /* height: 25%; */
        /* height: fit-content; */
        /* background-color: #FDF852;  */
        border-radius: 4vw;
        padding: 0px 4vw 10px 4vw;
        margin: 0 auto;
      }
      .yellow_sec_img {
        width: 100%;
      }
      .yellow_sec_p1 {
        width: fit-content;
        font-family: "Kaushan Script", cursive;
        font-size: clamp(15px, 9.5vw, 70px);
        margin: 0;
        margin: 0px auto;
        padding-right: 5px;
      }
      .yellow_sec_p2 {
        width: fit-content;
        font-family: "Kaushan Script", cursive;
        font-size: clamp(15px, 3.5vw, 30px);
        margin: 0;
        margin: 0px auto;
        padding-right: 5px;
      }

      .mail_content {
        font-family: "Josefin Sans", sans-serif;
        font-size: clamp(15px, 2.5vw, 18px);
        font-weight: 300;
        margin-top: 3%;
        padding: 2%;
      }
      .mail_content_p {
        margin: 0;
        margin-bottom: 10px;
      }

      .beta_try {
        font-family: "Josefin Sans", sans-serif;
        width: fit-content;
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
        align-items: center;
        margin: 0 auto;
      }
      .beta_try_p {
        font-size: clamp(15px, 2.5vw, 18px);
      }
      .beta_try_button {
        display: block;
        font-size: clamp(15px, 2vw, 18px);
        font-weight: 700;
        border: none;
        background-color: #BDFA3C;
        padding: 10px 25px;
        border-radius: 15px;
        margin: 0 auto;
        margin-bottom: 20px;
        cursor: pointer;
        transition: color 0.2s , background-color 0.2s ,transform 0.2s;
      }
      .beta_try_button:hover {
        color: white;
        background-color: #04aca4;
        transform: scale(1.1);
        transition: color 0.2s , background-color 0.2s ,transform 0.2s;
      }

      .follow_us {
        width: fit-content;
        font-family: "Josefin Sans", sans-serif;
        margin: 0px auto;
      }
      .follow_us_p {
        font-size: clamp(15px, 5vw, 18px);
        width: fit-content;
        margin: 0px auto;
        margin-top: 20px;
      }
      .follow_us_button {
        border: none;
        background-color: transparent;
        margin-top: 20px;
      }
      .follow_us_button_img {
        height: 30px;
        width: 30px;
      }
      /* @media (420px <= width) {
        .whole_bg{
          max-width: 375px;
        }
      }
      @media ( width >= 421px ) {
        .whole_bg{
          max-width: 768px;
        }
      } */
  </style>
</head>

<body>
  <div class="whole_bg">
    <div class="content_container">
      <header style="width: fit-content;margin: 0px auto;">
          <img style="width: clamp(150px, 20vw, 300px);" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Ftwigyy%20mail%20logo.png?alt=media&token=50426682-79fe-4cb9-b543-ffadc150f976"/>
      </header>

      <section class="yellow_sec">
        <!-- <p class="yellow_sec_p1">Thank You</p>
        <p class="yellow_sec_p2">For Your Feedback!</p> -->
        <img class="yellow_sec_img" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Fthankyou.png?alt=media&token=1fff8f15-7bf4-4234-ae4e-a668c0a5c4a1" />
      </section>

      <section class="mail_content">
        <p class="mail_content_p">Dear <span style="color: #67d120; font-weight: 400">${name}</span>,<br>
        We just wanted to take a moment to thank you for providing  your valuable feedback.</p>

        <p class="mail_content_p">Your love and support will really help us to improve. Keep up the positive vibes as each of you is unique in your way. We really hope you have good experience with us.</p>

        <p class="mail_content_p">Stay connected to us !</p>

        <p class="mail_content_p">Best Regards,<br/>
        <span style="color: #67d120; font-weight: 400">Twigyy</span></p>
      </section>

      <hr style="height: 0.5px; border: none; background-color: black;"/>

      <section class="beta_try">
        <p class="beta_try_p">Our Beta Program is Live.</p>
        <a target="_blank" href="https://beta.twigyy.com" style="width: 100% ; text-decoration: none;">
          <button class="beta_try_button" >Try Now</button>
        </a>
      </section>
    </div>   
    
    <div class="follow_us">
      <p class="follow_us_p">Follow Us</p>
      <button class="follow_us_button"><img class="follow_us_button_p" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Ffb%20logo.png?alt=media&token=58ea0a4b-d608-4344-9d22-78f4983171a0"/></button>
      <button class="follow_us_button"><img class="follow_us_button_p" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Finsta%20logo.png?alt=media&token=ea750668-48a9-45fd-87dd-0afb47c63eb0"/></button>
      <button class="follow_us_button"><img class="follow_us_button_p" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Fx%20logo.png?alt=media&token=a7d661df-11ba-4618-8a7f-a556a68b6a0d"/></button>
      <button class="follow_us_button"><img class="follow_us_button_p" src="https://firebasestorage.googleapis.com/v0/b/twigyy-1adce.appspot.com/o/Mail%20template%2Flinkedin%20logo.png?alt=media&token=f6850a2f-f95c-44e3-b49a-929caf259fec"/></button>
    </div>
  </div>
</body>
</html>`;

  const templateParams = {
    message_html: emailContent,
    to_email: email,
  };

  emailjs
    .send(
      "service_wliy1ok",
      "template_7h7k9p6",
      templateParams,
      "vaOYy6U9Q-yOrx0yQ"
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (error) => {
        console.error("FAILED...", error);
      }
    );
};

export default SendFeedbackReply;

import React from 'react'
import chats from '../Images/Chat.svg'
import chats_1 from '../Images/msg img/msg_1.svg'
import chats_2 from '../Images/msg img/msg_2.png'
import chats_3 from '../Images/msg img/msg_3.png'
import chats_4 from '../Images/msg img/msg_4.png'
import chats_5 from '../Images/msg img/msg_5.svg'
import chats_6 from '../Images/msg img/msg_6.png'

const Message_animation = () => {

  return (
<>
  <div id='chat_min_div' className='h-[100%] w-[80%] md:w-[500px] relative'>
      <img id='chat_1' className='op h-[8%] absolute left-0 top-2 md:top-0' src={chats_1} />
      <img id='chat_2' className='op h-[8%] absolute right-3 md:right-[19px] top-10' src={chats_2} />
      <img id='chat_3' className='op h-[8%] absolute right-0 top-16 md:top-20' src={chats_3} />
      <img id='chat_4' className='op h-[30%] absolute top-[110px] md:top-[130px]' src={chats_4} />
      <img id='chat_5' className='op h-[30%] absolute right-0 top-[230px] md:top-[320px]' src={chats_5} />
      <img id='chat_6' className='op h-[8%] absolute top-[350px] md:top-[520px]' src={chats_6} />
  </div>
</>
)
}

export default Message_animation
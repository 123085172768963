import React from 'react'
import home from '../Images/home.svg';
import settings from '../Images/settings.svg';
import lock from '../Images/lock.svg';

const Privacy_sidebar = () => {
  return (
    <>
    <div className='px-8 '>
    <div className='text-[24px] font-medium'>Privacy Center</div>
    <div className='p-8 flex flex-col gap-[20px]'>
    <div className='flex  text-[18px] content-center gap-[10px]  '>
        <img src={home}/>
        <p>Privacy Center Home</p>
    </div>
    <div className='flex  text-[18px] content-center gap-[10px]  '>
        <img src={settings}/>
        <p>Common Privacy Settings</p>
    </div>
    <div className='flex  text-[18px] content-center gap-[10px]  '>
        <img src={lock}/>
        <p>Privacy Policy</p>
    </div>

    </div>

    </div>
    
    
    </>
  )
}

export default Privacy_sidebar
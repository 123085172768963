import React from "react";

const Twigs = () => {
  return (
    <>
      <div className="flex flex-col gap-[20px] w-[100%]">
        <div className="font-medium text-[30px]">Twigs</div>
        <p>
          Learn how to share photo and video posts, edit them and add your
          location.
        </p>
        <div>
          <ul className="list-disc ">
            <p>How to upload Twigs from computer:</p>

            <li className="mx-[35px]">
              Click on account(👤) from the the navigation bar to go to your
              profile
            </li>
            <li className="mx-[35px]">
              Click on “Create Twigs”(🏜️) button under the “Edit Profile” button
            </li>
            <li className="mx-[35px]">Click on “Select from computer”</li>
            <li className="mx-[35px]">
              Select photos and videos from your computer
            </li>
            <li className="mx-[35px]">Click on “next”</li>
            <li className="mx-[35px]">
              Click on the crop option available on the left bottom corner. Crop
              the video if you want to resize it then click on “next”
            </li>
            <li className="mx-[35px]">Add caption and click on “Done”</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Twigs;

import React from 'react'
import Privacy_sidebar from '../Components/Privacy_sidebar'
import Privacy_middlebar from '../Components/Privacy_middlebar'
import Privacy_Policy from '../Components/Privacy_Policy'

const Privacy_main = () => {
  return (
    <>
    <div className='flex flex-row justify-center gap-24 mt-[30px]'>
        <Privacy_sidebar />
        <hr className='w-[1px] bg-[#828282] h-[2700px]'/>
        <div className='flex flex-col w-[80%]'>
        {/* <Privacy_middlebar/>
        <Privacy_middlebar/>
        <Privacy_middlebar/> */}
        <Privacy_Policy/>

        </div>
    </div>
    
    </>
  )
}

export default Privacy_main
import React, { useEffect } from "react";
import Page_1 from "../Components/Page_1";
import Page_2 from "../Components/Page_2";
import "../Styles/Main_pg.css";
import "../Styles/Animations.css";

import small_twigyy from '../Images/small_twigyy_logo.svg'


const Main_pg = () => {
  console.log(document.body.scrollHeight);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-in");
      // Home Page
      const home_1 = document.querySelector("#home_anime_1");
      const home_2 = document.querySelector("#home_anime_2");
      const home_1_element = home_1.getBoundingClientRect();
      const home_2_element = home_2.getBoundingClientRect();
      // Msg Page
      const msg_1 = document.querySelector("#msg_1");
      const msg_2 = document.querySelector("#msg_2");
      const chat_min_div = document.querySelector("#chat_min_div");
      const chat_1 = document.querySelector("#chat_1");
      const chat_2 = document.querySelector("#chat_2");
      const chat_3 = document.querySelector("#chat_3");
      const chat_4 = document.querySelector("#chat_4");
      const chat_5 = document.querySelector("#chat_5");
      const chat_6 = document.querySelector("#chat_6");
      const msg_1_element = msg_1.getBoundingClientRect();
      const msg_2_element = msg_2.getBoundingClientRect();
      const chat_min = chat_min_div.getBoundingClientRect();
      // Search Page
      const typewriter = document.querySelector("#typewriter");
      const img_anime_1 = document.querySelector("#img_anime_1");
      const img_anime_2 = document.querySelector("#img_anime_2");
      const img_anime_3 = document.querySelector("#img_anime_3");
      const typewriter_element = typewriter.getBoundingClientRect();
      const img_anime_1_element = img_anime_1.getBoundingClientRect();
      const img_anime_2_element = img_anime_2.getBoundingClientRect();
      const img_anime_3_element = img_anime_3.getBoundingClientRect();
      // take Page
      const take_1 = document.querySelector("#take_rl");
      const take_2 = document.querySelector("#take_lr");
      const take_3 = document.querySelector("#fall_down");
      const take_4 = document.querySelector("#popUp_take");
      const take_1_element = take_1.getBoundingClientRect();
      const take_2_element = take_2.getBoundingClientRect();
      const take_3_element = take_3.getBoundingClientRect();
      const take_4_element = take_4.getBoundingClientRect();

      // tales Page
      const tale_pop = document.querySelector("#tale_pop");
      const tale_pop_1 = document.querySelector("#tale_pop_1");
      const tale_pop_2 = document.querySelector("#tale_pop_2");
      const tale_slide = document.querySelector("#tale_slide");
      const tale_slide_1 = document.querySelector("#tale_slide_1");
      const tale_slide_2 = document.querySelector("#tale_slide_2");
      const tale_1 = document.querySelector("#tale_ms");
      const tale_1_element = tale_1.getBoundingClientRect();

      elements.forEach((element) => {
        //for all the pages Fade in animation
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          element.classList.add("visible");
        } else {
          element.classList.remove("visible");
        }
      });

      // Home Page Text Animation
      if (
        home_1_element.top >= 0 &&
        home_1_element.bottom <= window.innerHeight
      ) {
        home_1.classList.add("left_right_fade_in");
      } else {
        home_1.classList.remove("left_right_fade_in");
      }
      if (
        home_2_element.top >= 0 &&
        home_2_element.bottom <= window.innerHeight
      ) {
        home_2.classList.add("right_left_fade_in");
      } else {
        home_2.classList.remove("right_left_fade_in");
      }
      // Msg Page Text Animation
      if (
        msg_1_element.top >= 0 &&
        msg_1_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          msg_1.classList.add("popUp");
        }, 1000);
      } else {
        msg_1.classList.remove("popUp");
      }
      if (
        msg_2_element.top >= 0 &&
        msg_2_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          msg_2.classList.add("popUp_2");
        }, 2000);
      } else {
        msg_2.classList.remove("popUp_2");
      }

      if (chat_min.top >= 0 && chat_min.bottom <= window.innerHeight) {
        //chat animation
        setTimeout(() => {
          chat_1.classList.add("msg_popUp");
        }, 2000);
        setTimeout(() => {
          chat_2.classList.add("msg_popUp");
        }, 3000);
        setTimeout(() => {
          chat_3.classList.add("msg_popUp");
        }, 4000);
        setTimeout(() => {
          chat_4.classList.add("msg_popUp");
        }, 5000);
        setTimeout(() => {
          chat_5.classList.add("msg_popUp");
        }, 6000);
        setTimeout(() => {
          chat_6.classList.add("msg_popUp");
        }, 7000);
      } else {
        setTimeout(() => {
          chat_1.classList.remove("msg_popUp");
          chat_2.classList.remove("msg_popUp");
          chat_3.classList.remove("msg_popUp");
          chat_4.classList.remove("msg_popUp");
          chat_5.classList.remove("msg_popUp");
          chat_6.classList.remove("msg_popUp");
        }, 1000);
      }

      // Search Page Text Animation
      if (
        typewriter_element.top >= 0 &&
        typewriter_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          typewriter.classList.add("typewriter-text");
        }, 2000);
      } else {
        typewriter.classList.remove("typewriter-text");
      }

      if (
        img_anime_1_element.top >= 0 &&
        img_anime_1_element.bottom <= window.innerHeight
      ) {
        img_anime_1.classList.add("popUp");
      } else {
        img_anime_1.classList.remove("popUp");
      }
      if (
        img_anime_2_element.top >= 0 &&
        img_anime_2_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          img_anime_2.classList.add("right_left_search");
        }, 3000);
      } else {
        img_anime_2.classList.remove("right_left_search");
      }
      if (
        img_anime_3_element.top >= 0 &&
        img_anime_3_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          img_anime_3.classList.add("left_right_search");
        }, 3000);
      } else {
        img_anime_3.classList.remove("left_right_search");
      }

      // Takes Page Text Animation
      if (
        take_1_element.top >= 0 &&
        take_1_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          take_1.classList.add("right_left");
        }, 2000);
      } else {
        take_1.classList.remove("right_left");
      }
      if (
        take_2_element.top >= 0 &&
        take_2_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          take_2.classList.add("left_right");
        }, 2000);
      } else {
        take_2.classList.remove("left_right");
      }
      if (
        take_3_element.top >= 0 &&
        take_3_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          take_3.classList.add("fall_anime");
        }, 3000);
      } else {
        take_3.classList.remove("fall_anime");
      }
      if (
        take_4_element.top >= 0 &&
        take_4_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          take_4.classList.add("popUp_2");
        }, 4500);
      } else {
        take_4.classList.remove("popUp_2");
      }

      //Tales Page animation
      if (
        tale_1_element.top >= 0 &&
        tale_1_element.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          tale_pop.classList.add("tale_pop");
          tale_pop_1.classList.add("tale_pop");
          tale_pop_2.classList.add("tale_pop");
        }, 2000);
        setTimeout(() => {
          tale_slide.classList.add("tale_lr_anime");
          tale_slide_1.classList.add("tale_lr_anime");
          tale_slide_2.classList.add("tale_lr_anime");
        }, 3000);
      } else {
        tale_pop.classList.remove("tale_pop");
        tale_pop_1.classList.remove("tale_pop");
        tale_pop_2.classList.remove("tale_pop");
        tale_slide.classList.remove("tale_lr_anime");
        tale_slide_1.classList.remove("tale_lr_anime");
        tale_slide_2.classList.remove("tale_lr_anime");
      }
    };

    const scrollDiv = document.querySelector(".scroll_pg");
    scrollDiv.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      scrollDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const div1 = document.querySelector(".scroll_pg");
    const div2 = document.querySelector(".main_pg_scroll");
    // console.log(div1.scrollTop + div1.clientHeight +2529)

    const handleScroll = () => {
      // console.log('my scroll', div1.scrollTop + div1.clientHeight);
      // console.log('total height', div1.scrollHeight)

      if (div1.scrollTop === 0) {
        // Scroll div2 up when div1 reaches the top
        div2.scrollBy(0, -50); // Adjust -50 to scroll more or less
        // console.log('You are at the top of scroll_pg.');
      } else if (div1.scrollTop + div1.clientHeight + 1 >= div1.scrollHeight ) {
        // Scroll div2 down when div1 reaches the bottom
        div2.scrollBy(0, 100); // Adjust 50 to scroll more or less
        // console.log('You are at the bottom of scroll_pg.');
      }
    };

    div1.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      div1.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="h-[92dvh] md:h-[90vh] main_pg_scroll">
        <Page_1 />
        <Page_2 />

        <div className="h-[98%] md:h-[90%] py-10 px-5 md:px-20 flex flex-col md:flex-row items-center justify-around">
          <section className="w-[100%] md:w-[50%] ">
            <span className="block main_page_text_1">Welcome to Twigyy,</span>
            <p className="ml-10 mt-6 main_page_text_2">Twigyy is a vibrant social media platform where your creativity knows no bounds. Whether you're here to share your unique moments, discover new inspiration, or connect with like-minded individuals, Twigyy is the place for you. Dive into a world of stunning photos, captivating videos, and endless inspiration. Join our community today and start your journey to connect, create, and inspire. We're excited to see what you'll share!</p>
          </section>
            <img className="h-[100px] md:h-[200px]" src={small_twigyy} />
        </div>
      </div>
    </>
  );
};

export default Main_pg;

import React, { useEffect, useRef } from 'react'
import search from '../../Images/search.svg'
import Pop_1 from '../../Images/Pop_up_1.svg'
import Pop_2 from '../../Images/Pop_up_2.svg'
import search_icon from '../../Images/Search_icon.svg'

const Search_pg = (props) => {
  return (
<>

<div ref={props.searchRef} className='fade-in w-full h-fit flex flex-col-reverse md:flex-row items-center justify-around pl-2 md:pl-20'>
    <div className='relative scale-[.7] md:scale-100'>
        <img id='img_anime_1' className='h-[70%]' src={search} />
        <img id='img_anime_2' className='h-[12%] absolute top-20 right-[-50px] op' src={Pop_1} />
        <img id='img_anime_3' className='h-[12%] absolute bottom-20 left-[-20px] op' src={Pop_2} />
    </div>
    <div className='w-fit'>
        <section className='search_text_1'>
            <p className='flex'>Expl<img className='w-8 md:w-fit mt-2 md:mt-6' src={search_icon} />re endless</p>
            <p className='mx-auto w-fit'>Possibilities</p>
        </section>
        <section className='flex bg-white w-fit py-2 md:py-4 px-2 md:px-8 rounded-full border-[2px] border-black items-center gap-2 search_text_2 mt-10'>
            <img className='w-6 md:w-10' src={search_icon} />
            <p id='typewriter' className='text_visible'>Discover new connections effortlessly</p>
        </section>
    </div>
</div>

</>
)
}

export default Search_pg